(function () {
    'use strict';

    angular
        .module('supplyApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', 'LoginService', 'AlertService', '$state'];

    function HomeController($scope, Principal, LoginService, AlertService, $state) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;

        $scope.$on('authenticationSuccess', function () {
            loadData();
        });

        loadData();

        function loadData() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                if (account) {
                    initRoles();

                    if (Principal.hasOneAuthority('ROLE_USER')) {
                        window.location.replace("/cabinet");
                    }

                } else {
                    LoginService.open();
                }
            });
        }

        function initRoles() {
            vm.hasGlobalRole = Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_BASE_ADMIN', 'ROLE_BASE_ACC']);
            if (!vm.hasGlobalRole) {
                vm.hasSchoolRole = Principal.hasAnyAuthority(['ROLE_SCH_ADMIN', 'ROLE_SCH_ACC']);
                if (!vm.hasSchoolRole) {
                    vm.isTeacher = Principal.hasAnyAuthority(['ROLE_SCH_USER']);
                } else {
                    vm.hasUnknownRole = true;
                }
            }
        }

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;

            });
        }

        function register() {
            $state.go('register');
        }
    }
})();
